import Axios from 'axios';
import { operations } from '@/ts/ApiSpecs';

type IndexOntologyPathParams =
    operations['indexOntologyUsingPOST']['parameters']['path'];
type IndexOntologyResponse =
    operations['indexOntologyUsingPOST']['responses']['200']['content']['*/*'];

/**
 * Index an ontology for running SPARQL queries asynchronously
 * @param {string} ontologyId
 * @return {IndexOntologyResponse}
 */
export const indexOntologyAsync = async ({
    ontologyId,
}: IndexOntologyPathParams) => {
    const url = `/sparql/ontologies/${ontologyId}/index/async`;
    const response = await Axios.post(url);

    return response.data as IndexOntologyResponse;
};
