<template>
    <div>
        <YasguiInterceptModal />
        <SparqlPresetMenu @addPresetTab="addPresetTab" />
        <div class="button-area" />
        <div class="yasgui-wrapper">
            <div class="yasgui" id="yasgui" />
        </div>
        <span class="select-prefixes">
            <label class="select-ontology-label">Selected Ontology:</label>
            <v-select
                class="select-indexed-ontology"
                :options="
                    loadedOntologies.map((ontology) => ontology.ontologyId)
                "
                v-model="localSelectedOntology"
                placeholder="Select an Ontology..."
                :clearable="true"
                :searchable="true"
                taggable />

            <vue-bootstrap-typeahead
                class="prefix-typeahead"
                v-model="prefixSearchInput"
                ref="prefixSearch"
                :data="allPrefixes"
                :serializer="(prefix) => prefix.name"
                :minMatchingChars="1"
                placeholder="Search and add a prefix"
                size="sm"
                @hit="addPrefix($event)"
        /></span>
        <SparqlIndexModal />
    </div>
</template>

<script>
import {
    computed,
    defineComponent,
    onMounted,
    ref,
    watch,
} from '@vue/composition-api';
import Yasgui from '@triply/yasgui';
import '@triply/yasgui/build/yasgui.min.css';
import SparqlPreset from '@/components/Sparql/SparqlPreset';
import SparqlPresetMenu from '@/components/Sparql/SparqlPresetMenu';
import SparqlIndexModal from '@/components/Sparql/SparqlIndexModal';
import { useSparql } from '@/compositions/useSparql';
import SparqlPrefixes from '@/utils/sparql/SparqlPrefixes';
import getPrefixIRI from '@/utils/sparql/SparqlPrefixes';
import VueBootstrapTypeahead from 'vue-bootstrap-typeahead';
import YasguiInterceptModal from '@/components/Sparql/YasguiInterceptModal.vue';
import getAllLoadedOntologies from '@/utils/ontologies/getAllLoadedOntologies';
import getSparqlIndexedOntologies from '@/utils/sparql/getIndexedOntologies';
import getAllUnloadedOntologies from '@/utils/ontologies/getAllUnloadedOntologies';
import { useStore } from '@/compositions';

export default defineComponent({
    name: 'Sparql',
    components: {
        YasguiInterceptModal,
        SparqlIndexModal,
        SparqlPresetMenu,
        SparqlPreset,
        VueBootstrapTypeahead,
    },
    setup(_, context) {
        let yasgui;
        const queryLimit = ref(100);
        const prefixSearchInput = ref('');
        const allPrefixes = ref([]);
        const localSelectedOntology = ref('');

        const getAuthToken = () => {
            const token = useStore().getters.token;
            if (!token || token === '') return '';
            return 'Bearer ' + token.replaceAll('"', '');
        };

        onMounted(async () => {
            //Get Loaded and Indexed ontologies
            useSparql().setLoadedOntologies(await getAllLoadedOntologies());
            useSparql().setUnloadedOntologies(await getAllUnloadedOntologies());
            useSparql().setIndexedOntologies(
                await getSparqlIndexedOntologies()
            );

            const getBackendHandover = () => {
                const query = localStorage.getItem('sparqlAdminHandoff');
                if (!query || query.trim() === '') return;

                yasgui.addTab(
                    true, // set as active tab
                    {
                        requestConfig: {
                            endpoint: process.env.VUE_APP_API_URL + '/sparql',
                            headers: () => ({
                                Authorization: getAuthToken(),
                            }),
                            method: 'POST',
                        },
                        name: 'Admin Query',
                    }
                );
                yasgui.getTab().getYasqe().setValue(query);
                yasgui.getTab().getYasqe().query();
                localStorage.removeItem('sparqlAdminHandoff');

                return query;
            };

            //Setup Yasgui (Cache the location.hash and reset if after Yasgui load, due to know bug with Vue and Yasgui.)
            const hash = location.hash;
            location.hash = '';

            yasgui = new Yasgui(document.getElementById('yasgui'), {
                requestConfig: {
                    endpoint: process.env.VUE_APP_API_URL + '/sparql',
                    headers: () => ({
                        Authorization: getAuthToken(),
                    }),
                    method: 'POST',
                },
                copyEndpointOnNewTab: false,
                autofocus: true,
            });

            getBackendHandover();

            const defaultQuery = () => {
                return `PREFIX rdfs: <http://www.w3.org/2000/01/rdf-schema#>
PREFIX owl: <http://www.w3.org/2002/07/owl#>
PREFIX skos: <http://www.w3.org/2004/02/skos/core#>
PREFIX skosxl: <http://www.w3.org/2008/05/skos-xl#>

SELECT * FROM <http://www.scibite.com/ontologies/rdf/null>

WHERE {
  ?s ?p ?o .
} LIMIT 100`;
            };

            yasgui.config.yasqe.value = defaultQuery();

            //Check if the hardcoded YASGUI default query had loaded
            if (
                yasgui.getTab().getQuery() ==
                'PREFIX rdf: <http://www.w3.org/1999/02/22-rdf-syntax-ns#>\n' +
                    'PREFIX rdfs: <http://www.w3.org/2000/01/rdf-schema#>\n' +
                    'SELECT * WHERE {\n' +
                    '  ?sub ?pred ?obj .\n' +
                    '} LIMIT 10'
            ) {
                //If it has replace it with our custom default query and rename the tab
                yasgui.getTab().setQuery(defaultQuery());
                yasgui.getTab().setName('Triples');
            }

            //reset cached hash routing
            location.hash = hash;
            allPrefixes.value = await getPrefixIRI();

            //Check the URL for a specified OntologyId
            if (context.root?.$route?.params?.ontologyID) {
                localSelectedOntology.value =
                    context.root?.$route?.params?.ontologyID;
            } else {
                localSelectedOntology.value = null;
            }
        });

        function interceptClickEvent(e) {
            const target = e.target || e.srcElement;
            if (target.tagName === 'A') {
                const targetClass = target.getAttribute('class');
                const href = target.getAttribute('href');

                if (targetClass == 'iri') {
                    useSparql().setInterceptIri(href);
                    e.preventDefault();
                }
            }
        }

        //listen for link click events at the document level
        if (document.addEventListener) {
            document.addEventListener('click', interceptClickEvent);
        } else if (document.attachEvent) {
            document.attachEvent('onclick', interceptClickEvent);
        }

        const replaceQuery = (query) => {
            yasgui.getTab().getYasqe().setValue(query);
        };

        const addPresetTab = (title, query) => {
            query = query.replaceAll(
                '*ONTOLOGYID*',
                localSelectedOntology.value == null
                    ? 'ontologyID'
                    : localSelectedOntology.value
            );
            query = query.replaceAll('*LIMIT*', queryLimit.value);
            yasgui.addTab(
                true, // set as active tab
                {
                    requestConfig: {
                        endpoint: process.env.VUE_APP_API_URL + '/sparql',
                        headers: () => ({
                            Authorization: getAuthToken(),
                        }),
                        method: 'POST',
                    },
                    name: title,
                }
            );
            yasgui.getTab().getYasqe().setValue(query);
        };

        const selectedOntology = computed(() => {
            return useSparql().getSelectedOntology();
        });

        const addPrefix = (prefix) => {
            const newPrefix = {};
            newPrefix[prefix.name] = prefix.IRI;
            yasgui.getTab().getYasqe().addPrefixes(newPrefix);

            const prefixTypeahead = context.refs.prefixSearch;
            if (prefixTypeahead) {
                prefixTypeahead.inputValue = '';
                prefixTypeahead.value = '';
            }
        };

        const loadedOntologies = computed(() => {
            return useSparql().getLoadedOntologies();
        });

        const indexedOntologies = computed(() => {
            return useSparql().getIndexedOntologies();
        });

        //Updates the current tab with the selected ontology
        watch(localSelectedOntology, async (newValue) => {
            useSparql().setSelectedOntologyId(newValue);

            const replaceValue = newValue || 'ontologyID';

            const selectedOntologyRegex = new RegExp(
                `http://www\\.scibite\\.com/ontologies/rdf/.+>`,
                'g'
            );
            if (yasgui.getTab() && yasgui.getTab().name !== 'Admin Query') {
                replaceQuery(
                    yasgui
                        .getTab()
                        .getQuery()
                        .replaceAll(
                            selectedOntologyRegex,
                            `http://www.scibite.com/ontologies/rdf/${replaceValue}>`
                        )
                );
            }

            //Update the indexed list, to check if any edits have outdated the index without needing to refresh the page.
            useSparql().setIndexedOntologies(
                await getSparqlIndexedOntologies()
            );

            if (useSparql().isOntologyLoaded(newValue)) {
                const indexData = useSparql().isOntologyIndexed(newValue);
                if (indexData) {
                    useSparql().setSelectedOntology({
                        ontologyId: newValue,
                        status: 'READY',
                        indexOutdated:
                            indexData.indexedAt <= indexData.editedAt || false,
                        lastIndexDate: indexData.indexedAt,
                        lastTransactionDate: indexData.editedAt,
                    });
                } else {
                    useSparql().setSelectedOntology({
                        ontologyId: newValue,
                        status: 'NOT INDEXED',
                    });
                }
            } else if (useSparql().isOntologyUnloaded(newValue)) {
                useSparql().setSelectedOntology({
                    ontologyId: newValue,
                    status: 'NOT LOADED',
                });
            } else {
                //The ontology could not be found in Centree
                useSparql().setSelectedOntology({
                    ontologyId: newValue,
                    status: 'NOT FOUND',
                });
            }
        });

        return {
            replaceQuery,
            addPresetTab,
            selectedOntology,
            SparqlPrefixes,
            allPrefixes,
            VueBootstrapTypeahead,
            addPrefix,
            prefixSearchInput,
            loadedOntologies,
            indexedOntologies,
            localSelectedOntology,
        };
    },
});
</script>

<style scoped lang="scss">
@import 'src/scss/variables.scss';

.yasgui-wrapper {
    margin-bottom: -5rem;
    width: 100%;
}

.yasgui {
    position: absolute;
    top: 8.75rem;
    bottom: 3rem;
    width: 98%;
    margin: 1rem;
}

.button-area {
    position: fixed;
    right: 0rem;
    background-color: white;
    min-height: 3rem;
    min-width: 17rem;
    top: 4.15rem;
    z-index: 5;
    left: 80vw;
}

::v-deep.yasgui .yasqe {
    margin-top: 0.25rem;

    .yasqe_buttons {
        position: fixed;
        margin-top: 4rem;
        margin-right: 8rem;

        .yasqe_share {
            display: none;
        }

        .yasqe_queryButton {
            scale: 0.7;
            margin-top: -0.15rem;
            margin-right: 0.5rem;
        }
    }
}

::v-deep.yasgui .yasr .yasr_results {
    position: relative;
    padding-bottom: 3rem;
    z-index: 0;
}

::v-deep.yasgui .autocompleteWrapper {
    display: none !important;
}

::v-deep.yasgui .controlbar {
    position: relative;
    height: 0rem;
    margin-top: 0.5rem;
    display: flex;
    flex-wrap: wrap;
}

::v-deep.yasgui .tabContextButton {
    position: fixed;
    right: 15rem;
    margin-right: -1.5rem;
    top: 4.6rem;
    scale: 1.8;
    z-index: 10;
}

::v-deep.yasgui .tabsList {
    //position: fixed;
    z-index: 10;
    background: white;
    max-width: 50vw;
}

::v-deep.yasgui .tabMenu {
    right: -600px;
    left: auto !important;
    position: fixed;
    top: 7.5rem;
}

::v-deep.yasgui .switch {
    margin-top: 0.5rem !important;
    margin-right: 1rem !important;

    .label {
        margin-right: 0.25rem !important;
    }
}

.select-prefixes {
    z-index: 200;
    position: fixed;
    top: 75px;
    display: flex;
    right: 16.75rem;
    flex-wrap: wrap;
}

@media only screen and (max-width: 1600px) {
    .select-prefixes {
        left: 55vw !important;
    }

    ::v-deep.yasgui .tabsList {
        min-height: 90px;
    }
}

@media only screen and (max-width: 1000px) {
    ::v-deep.yasgui .tabsList {
        min-height: 125px;
    }
}

.select-indexed-ontology {
    min-width: 12rem;
    margin-right: 1rem;
    scale: 1;
    transform: translateY(-2px);
}

.select-ontology-label {
    transform: translateY(2px);
    font-weight: 400;
    margin-right: 0.25rem;
}

.prefix-typeahead {
    scale: 1.1;
    min-width: 5rem;
}
</style>
