<template>
    <div>
        <b-button class="sidebar-button" v-b-toggle.presets-sidebar
            >Show Presets</b-button
        >

        <b-sidebar class="presets-sidebar" id="presets-sidebar" right shadow>
            <template #header="">
                <div class="sidebar-header">
                    <strong>Presets:</strong>

                    <div class="query-limiter-wrapper">
                        <label class="query-limiter-label">Query Limit:</label>
                        <b-form-input
                            class="input-query-limit"
                            id="query-limit"
                            v-model="localQueryLimit"
                            type="number"
                            size="sm" />
                    </div>

                    <b-button variant="primary" v-b-toggle.presets-sidebar
                        >Close</b-button
                    >
                </div>
            </template>

            <SparqlPreset
                v-for="(preset, index) in SparqlPresets"
                :key="index"
                :title="
                    preset.title.replaceAll('*LIMIT*', queryLimit.toString())
                "
                :description="
                    preset.description.replaceAll(
                        '*LIMIT*',
                        queryLimit.toString()
                    )
                "
                :query="
                    preset.query.replaceAll('*LIMIT*', queryLimit.toString())
                "
                :input="preset.input"
                :classSelect="preset.classSelect"
                :propertySelect="preset.propertySelect"
                @selectPreset="selectPreset" />
        </b-sidebar>
    </div>
</template>

<script>
import {
    computed,
    defineComponent,
    onMounted,
    ref,
    watch,
} from '@vue/composition-api';
import SparqlPreset from '@/components/Sparql/SparqlPreset';
import SparqlPresets from '@/utils/sparql/SparqlPresets';
import { useSparql } from '@/compositions/useSparql';

export default defineComponent({
    name: 'SparqlPresetMenu',
    components: { SparqlPreset },
    setup(props, { emit }) {
        const localQueryLimit = ref(100);

        const selectPreset = (title, query) => {
            emit('addPresetTab', title, query);
        };

        watch(localQueryLimit, (newValue) => {
            useSparql().setQueryLimit(newValue);
        });

        const queryLimit = computed(() => {
            return useSparql().getQueryLimit();
        });

        onMounted(() => {
            const presets = document.querySelectorAll('.preset');

            const presetObserver = new IntersectionObserver(
                (presets) => {
                    presets.forEach((preset) => {
                        preset.target.classList.toggle(
                            'show-preset',
                            preset.isIntersecting
                        );
                    });
                },
                {
                    threshold: 0.1,
                }
            );

            presets.forEach((preset) => {
                presetObserver.observe(preset);
            });
        });

        return {
            SparqlPresets,
            selectPreset,
            localQueryLimit,
            queryLimit,
        };
    },
});
</script>

<style scoped lang="scss">
::v-deep.presets-sidebar {
    .b-sidebar {
        width: 35em !important;
    }
}

.sidebar-button {
    position: fixed;
    right: 1%;
    margin-top: 9.5rem;
    background-color: #f7ac50;
    color: black;
    outline: none;
    z-index: 12;
}

.sidebar-header {
    display: flex;
    flex-grow: 1;
    justify-content: space-between;
}

.query-limiter-wrapper {
    transform: translateY(0.3rem);
    display: flex;
    justify-content: center;
}

.query-limiter-label {
    font-size: 16px;
    transform: translateY(0.2rem);
    display: inline;
}

.input-query-limit {
    margin-left: 0.5rem;
    max-width: 30%;
    display: inline;
}
</style>
