import { getAllOntologyMetadataSummary } from '@/api-v2';

const getAllLoadedOntologies = async () => {
    const response = await getAllOntologyMetadataSummary({
        loadStatusToFilterBy: 'loaded',
    });
    return response;
};

export default getAllLoadedOntologies;
