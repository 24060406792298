import { getAllOntologyMetadataSummary } from '@/api-v2';

const getAllUnloadedOntologies = async () => {
    const response = await getAllOntologyMetadataSummary({
        loadStatusToFilterBy: 'unloaded',
    });
    return response;
};

export default getAllUnloadedOntologies;
