<template>
    <b-modal
        v-model="localModalShow"
        ref="intercept-modal"
        :title="shortFormId">
        <strong
            >Would you like to search in Centree, or proceed to the IRI?</strong
        >
        <i>{{ iri }}</i>
        <template v-slot:modal-footer>
            <b-button size="sm" variant="secondary" @click="onClickExternal">
                Open Externally
                <font-awesome-icon
                    class="action-btn route-btn"
                    icon="external-link-alt"
                    v-b-tooltip.hover="{
                        title: `Open ${iri}`,
                        delay: { show: 300, hide: 0 },
                    }"
                    @click.stop.prevent="onClickCentree" />
            </b-button>
            <b-button size="sm" variant="primary" @click="onClickCentree">
                Search in Centree
                <font-awesome-icon
                    class="action-btn"
                    icon="search"
                    v-b-tooltip.hover="{
                        title: `Search Centree for ${shortFormId}`,
                        delay: { show: 300, hide: 0 },
                    }"
                    @click.stop.prevent="onClickCentree" />
            </b-button>

            <slot name="footer"></slot>
        </template>
    </b-modal>
</template>

<script>
import { computed, defineComponent, ref, watch } from '@vue/composition-api';
import { useSparql } from '@/compositions/useSparql';
import router from '@/router';

export default defineComponent({
    name: 'YasguiInterceptModal',
    setup(props, context) {
        const localModalShow = ref(false);

        const iri = computed(() => {
            return useSparql().getInterceptIri();
        });

        const shortFormId = computed(() => {
            const split = useSparql().getInterceptIri().split('/');
            if (!split.length) return;
            return split[split.length - 1];
        });

        const onClickCentree = () => {
            // useEventBus().getEventBus.value.$emit('typeahead:set', iri.value); //Replaces search value

            router.push({
                name: 'search-query',
                params: { query: iri.value },
            });

            useSparql().setInterceptIri('');
        };

        const onClickExternal = () => {
            const tab = window.open(iri.value, '_blank');
            tab.focus();
            useSparql().setInterceptIri('');
        };

        watch(useSparql().getInterceptIri, (current) => {
            if (current === '') localModalShow.value = false;
            else localModalShow.value = true;
        });

        return {
            localModalShow,
            iri,
            shortFormId,
            onClickCentree,
            onClickExternal,
        };
    },
});
</script>

<style scoped></style>
