const getPrefixIRI = async () => {
    let prefixes = [{}];
    try {
        const res = await fetch('https://prefix.cc/context');
        //Using type any, as this is a third party endpoint, so the content may change. (Fallback is in place below)
        const json: any = await res.json();
        prefixes = json['@context'];
    } catch (err) {
        console.warn(
            'Falling back to offline prefix list, this may not be up to date'
        );
        prefixes = require('@/utils/sparql/OfflinePrefixes.json');
    }

    const prefixBlacklist: string[] = ['dick'];

    return Object.entries(prefixes)
        .map((e) => ({
            name: e[0],
            IRI: e[1],
        }))
        .filter((prefix) => !prefixBlacklist.includes(prefix.name));
};

export default getPrefixIRI;
