const presets = [
    {
        title: 'Triples',
        description:
            'Return all the triples in the ontology. \n(Limit the results to *LIMIT*)',
        query:
            'PREFIX rdfs: <http://www.w3.org/2000/01/rdf-schema#>\n' +
            'PREFIX owl: <http://www.w3.org/2002/07/owl#>\n' +
            '\n' +
            'SELECT * FROM <http://www.scibite.com/ontologies/rdf/*ONTOLOGYID*>\n' +
            '\n' +
            'WHERE {\n' +
            '  ?s ?p ?o .\n' +
            '} LIMIT *LIMIT*',
    },
    {
        title: 'Classes and Labels',
        description:
            'Return all the classes and labels in the ontology. \n(Limit the results to *LIMIT*)',
        query:
            'PREFIX rdfs: <http://www.w3.org/2000/01/rdf-schema#>\n' +
            'PREFIX owl: <http://www.w3.org/2002/07/owl#>\n' +
            '\n' +
            'SELECT * FROM <http://www.scibite.com/ontologies/rdf/*ONTOLOGYID*>\n' +
            'WHERE {\n' +
            '  ?class a owl:Class .\n' +
            '  ?class rdfs:label ?Label\n' +
            '} LIMIT *LIMIT*',
    },
    {
        title: 'Label contains word',
        description:
            'Return all classes and Labels where the Label contains a given word. \n(Limit the results to *LIMIT*)',
        input: true,
        query:
            'PREFIX rdfs: <http://www.w3.org/2000/01/rdf-schema#>\n' +
            'PREFIX owl: <http://www.w3.org/2002/07/owl#>\n' +
            '\n' +
            'SELECT * FROM <http://www.scibite.com/ontologies/rdf/*ONTOLOGYID*>\n' +
            'WHERE {\n' +
            '  ?class a owl:Class .\n' +
            '  ?class rdfs:label ?Label .\n' +
            '  FILTER CONTAINS(?Label,"*INPUT*")\n' +
            '} LIMIT *LIMIT*',
    },
    {
        title: 'Children of class',
        description:
            'Return all children of a selected class. \n(Limit the results to *LIMIT*)',
        classSelect: true,
        query: `PREFIX rdfs: <http://www.w3.org/2000/01/rdf-schema#>
PREFIX owl: <http://www.w3.org/2002/07/owl#>

SELECT * FROM <http://www.scibite.com/ontologies/rdf/*ONTOLOGYID*>
WHERE {
  ?URI rdfs:subClassOf <*CLASS*> .
} LIMIT *LIMIT*`,
    },
    {
        title: 'Children & Ancestors of class',
        description:
            'Return all the children and ancestors of a selected class. \n(Limit the results to *LIMIT*)',
        classSelect: true,
        query:
            'PREFIX rdfs: <http://www.w3.org/2000/01/rdf-schema#>\n' +
            'PREFIX owl: <http://www.w3.org/2002/07/owl#>\n' +
            '\n' +
            'SELECT * FROM <http://www.scibite.com/ontologies/rdf/*ONTOLOGYID*>\n' +
            'WHERE {\n' +
            '  ?URI rdfs:subClassOf? <*CLASS*> .\n' +
            '  ?URI rdfs:label ?Label' +
            '} LIMIT *LIMIT*',
    },
    {
        title: 'Exact synonyms of class',
        description:
            'Return all exact synonyms of a selected class. \n(Limit the results to *LIMIT*)',
        classSelect: true,
        query:
            'PREFIX rdfs: <http://www.w3.org/2000/01/rdf-schema#>\n' +
            'PREFIX owl: <http://www.w3.org/2002/07/owl#>\n' +
            'PREFIX oboInOwl: <http://www.geneontology.org/formats/oboInOwl#>\n' +
            '\n' +
            'SELECT * FROM <http://www.scibite.com/ontologies/rdf/*ONTOLOGYID*>\n' +
            'WHERE { \n' +
            '  <*CLASS*> oboInOwl:hasExactSynonym ?Synonym .\n' +
            '} LIMIT *LIMIT*',
    },
    {
        title: 'Leaf nodes of class',
        description:
            'Return all leaf nodes of a selected class. \n(Limit the results to *LIMIT*)',
        classSelect: true,
        query:
            'PREFIX rdfs: <http://www.w3.org/2000/01/rdf-schema#>\n' +
            'PREFIX owl: <http://www.w3.org/2002/07/owl#>\n' +
            '\n' +
            'SELECT * FROM <http://www.scibite.com/ontologies/rdf/*ONTOLOGYID*>\n' +
            'WHERE {\n' +
            '  ?URI rdfs:subClassOf* <*CLASS*> .\n' +
            '  ?URI rdfs:label ?Label\n' +
            'FILTER NOT EXISTS \n' +
            '  { ?x rdfs:subClassOf ?URI }\n' +
            '} LIMIT *LIMIT*',
    },
    {
        title: 'Object properties',
        description:
            'Return all properties of the selected ontology. \n(Limit the results to *LIMIT*)',
        query:
            'PREFIX rdfs: <http://www.w3.org/2000/01/rdf-schema#>\n' +
            'PREFIX owl: <http://www.w3.org/2002/07/owl#>\n' +
            '\n' +
            'SELECT * FROM <http://www.scibite.com/ontologies/rdf/*ONTOLOGYID*>\n' +
            'WHERE {\n' +
            '  ?p a owl:ObjectProperty .\n' +
            '  ?p rdfs:label ?label\n' +
            '} LIMIT *LIMIT*',
    },
    {
        title: 'Classes with short labels',
        description:
            'Return all classes which have labels containing fewer than 4 characters. \n(Limit the results to *LIMIT*)',
        query:
            'PREFIX rdfs: <http://www.w3.org/2000/01/rdf-schema#>\n' +
            'PREFIX owl: <http://www.w3.org/2002/07/owl#>\n' +
            '\n' +
            'SELECT * FROM <http://www.scibite.com/ontologies/rdf/*ONTOLOGYID*>\n' +
            'WHERE {\n' +
            '?class a owl:Class .\n' +
            ' ?class rdfs:label ?Label .\n' +
            '  FILTER regex(?Label, "^.{0,3}$")\n' +
            '} LIMIT *LIMIT*',
    },
    {
        title: 'Parents of a given class',
        description:
            'Return all superclasses of a selected class. \n(Limit the results to *LIMIT*)',
        classSelect: true,
        query:
            'PREFIX rdfs: <http://www.w3.org/2000/01/rdf-schema#>\n' +
            'PREFIX owl: <http://www.w3.org/2002/07/owl#>\n' +
            '\n' +
            'SELECT * FROM <http://www.scibite.com/ontologies/rdf/*ONTOLOGYID*>\n' +
            'WHERE {\n' +
            '  ?URI rdfs:subClassOf* <*CLASS*> .\n' +
            '  ?URI rdfs:label ?Label\n' +
            'FILTER NOT EXISTS \n' +
            '  { ?x rdfs:subClassOf ?URI }\n' +
            '} LIMIT *LIMIT*',
    },
    {
        title: 'Obsoleted Classes',
        description:
            'Return all obsoleted classes in the selected ontology. \n(Limit the results to *LIMIT*)',
        query:
            'PREFIX rdfs: <http://www.w3.org/2000/01/rdf-schema#>\n' +
            'PREFIX owl: <http://www.w3.org/2002/07/owl#>\n' +
            '\n' +
            'SELECT * FROM <http://www.scibite.com/ontologies/rdf/*ONTOLOGYID*>\n' +
            'WHERE {\n' +
            '  ?URI a owl:Class .\n' +
            '  ?URI owl:deprecated ?ObsoleteValue .\n' +
            '  ?URI rdfs:label ?Label\n' +
            '} LIMIT *LIMIT*',
    },
    //Disabled as this is not working as of 2.4.2
    // {
    //     title: 'Ontology Graphs',
    //     description:
    //         'Return all the ontology graphs that are available to query. \n(Limit the results to *LIMIT*)',
    //     query:
    //         'PREFIX rdfs: <http://www.w3.org/2000/01/rdf-schema#>\n' +
    //         'PREFIX owl: <http://www.w3.org/2002/07/owl#>\n' +
    //         '\n' +
    //         'SELECT DISTINCT ?ontologyGraph FROM <http://www.scibite.com/ontologies/rdf/*ONTOLOGYID*>\n' +
    //         'WHERE { \n' +
    //         '   GRAPH ?ontologyGraph { \n' +
    //         '     ?s ?p ?o\n' +
    //         '   }\n' +
    //         '} LIMIT *LIMIT*',
    // },
    {
        title: 'Class Count',
        description:
            'Return the total number of classes in the selected ontology. \n(Limit the results to *LIMIT*)',
        query:
            'PREFIX rdfs: <http://www.w3.org/2000/01/rdf-schema#>\n' +
            'PREFIX owl: <http://www.w3.org/2002/07/owl#>\n' +
            '\n' +
            'SELECT (count (distinct ?s) as ?classCount) FROM <http://www.scibite.com/ontologies/rdf/*ONTOLOGYID*> \n' +
            'WHERE { \n' +
            '   ?s a owl:Class\n' +
            '}\n' +
            'GROUP BY ?classCount',
    },
    {
        title: 'Tags of class',
        description:
            'Return all tags of a selected class. \n(Limit the results to *LIMIT*)',
        classSelect: true,
        query:
            'PREFIX rdfs: <http://www.w3.org/2000/01/rdf-schema#>\n' +
            'PREFIX owl: <http://www.w3.org/2002/07/owl#>\n' +
            'PREFIX oboInOwl: <http://www.geneontology.org/formats/oboInOwl#>\n' +
            '\n' +
            'SELECT ?class ?synonym ?tagProperty ?tagValue FROM <http://www.scibite.com/ontologies/rdf/*ONTOLOGYID*>\n' +
            ' WHERE { \n' +
            '\n' +
            '    ?class a owl:Class .\n' +
            '    ?class oboInOwl:hasExactSynonym ?synonym .\n' +
            '    \n' +
            '    OPTIONAL {\n' +
            '\t?axiom a owl:Axiom ;\n' +
            '              owl:annotatedSource ?class ;\n' +
            '              owl:annotatedProperty oboInOwl:hasExactSynonym ;\n' +
            '              owl:annotatedTarget ?synonym ; \n' +
            '              ?tagProperty ?tagValue \n' +
            '     }\n' +
            '\n' +
            '     FILTER (?tagProperty NOT IN (owl:annotatedSource, owl:annotatedProperty, owl:annotatedTarget))\n' +
            '} LIMIT *LIMIT*',
    },
];

export default presets;
